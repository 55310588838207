import localization from 'reactor/localization';

localization.extend('en', {
    Home: 'HOME',
    Products: 'OUR PRODUCTS',
    Projects: 'OUR PROJECTS',
    Partners: 'OUR PARTNERS',
    Clients: 'OUR CLIENTS',
    About: 'ABOUT US',
    Contact: 'CONTACT US',
    footerPages: 'Pages',
    ourLocations: 'Our Locations',
    headOffice: 'Head Office & Factories',
    octoberBranch: 'October Branch',
    officeLocation: 'Abd El-Salam Abo El-Ezz St From El-Thalathiny St Behind Ard El-Gouhary, Kaliop, Egypt',
    octoberBranchLocation: 'Industrial Area, Along Third Piece 403 City, Egypt.',
    technoFiber: 'TECHNO FIBER',
    fiberGlass: 'FiberGlass',
    followUs: 'Follow Us On Social Platforms',
    contactName: 'Enter Your Full Name..',
    contactMail: 'Enter Your E-Mail..',
    contactNumber: 'Enter Your Mobile Number..',
    contactMessage: 'Enter Your Message Here..',
    contactSubject: 'Enter Your Subject Here..',
    contactBtn: 'Submit',
    viewAll: 'View All',
    Overview: 'Overview',
    technoFiberCompany: 'Techno Fiber Company',
    technoDescription1: 'A Pioneering company specialized in manufacturing and marketing Fiberglass products.',
    technoDescription2: 'The companys products are distinguished by their diversity and high quality, The company played a pioneering role in the introduction of moulded grating industry in Egypt.',
    ReadMore: 'Read More',
    ForMore: 'For More',
    TechnoFiberCompany: 'Techno Fiber Company',
    OurFactory: 'Our Factory',
    TechnoFiberCompanyDescription: 'A pioneering company specialized in manufacturing and marketing fiberglass products. The companys products are distinguished by their diversity and high quality. The company played a pioneering role in the introduction of Moulded Grating industry in Egypt. Its the only company in Egypt that has a Pultrusion Line for producing different F.R.P Profile that serve as an alternative to iron and aluminum Profile.',
    OurFactoryDescription: 'A pioneering company specialized in manufacturing and marketing fiberglass products. The companys products are distinguished by their diversity and high quality. The company played a pioneering role in the introduction of Moulded Grating industry in Egypt. Its the only company in Egypt that has a Pultrusion Line for producing different F.R.P Profile that serve as an alternative to iron and aluminum Profile.',
    OurVision: 'Our Vision',
    OurVisionDescription: 'We have more than 25 years of experience in the field of designing and manufacturing all fiberglass products, and during this period we were able to deal with all sectors of the country, and therefore our company looks forward to raising the level of quality to the highest international quality standards to compete in all global markets.',
    GetInTouchWithUs: 'Get In Touch With Us',
    Mail: 'E-Mail',
    Address: 'Address',
    AddressLocation: 'Abd El-Salam Abo El-Ezz St From El-Thalathiny St Behind Ard El-Gouhary, Kaliop, Egypt',
    Phone: 'Phone',
    SendUsMessage: 'Send Us Message',
    FastLinks: 'Fast Links',
    suggestedProducts:'Suggested Products',
    advantages: 'Advantages',
    utilities: 'Utilities',

    typeOne: 'FRP Pultrusion Profiles',
    typeTwo: 'FRP Grating',
    typeThree: 'Tanks',
    typeFour: 'Guard Booths and Caravans',
    typeFive: 'Manhole Covers',
    typeSix: 'Lining Works',
    typeSeven: 'Kid Games and Toys',
    typeEight: 'Corrugated Sheet',
    typeNine: 'Chairs and Sports Arenas',
    typeTen: 'Car Cabines',

    TypeOneDescription: 'These profiles are new to the egyptian and Arab market. They serve as an alternative to iron and aluminum profiles, series of our pultrusion profiles include tube, rectangular tube, round tube, L-angle, I-beam, channel, rod and other custom Profiles. These profiles have a great ability to achieve a wide-ranging and rapid market penetration in several fields because of their multi-Application. Techno Fiber have the first line of these products on the Egyptian market.',
    FRPAdvantagesNumberOne: 'High resistance to environmental and chemical factors',
    FRPAdvantagesNumberTwo: 'Lightweight with high strength',
    FRPAdvantagesNumberThree: 'Resistant to corrosion',
    FRPAdvantagesNumberFour: 'Non magnetism and non conductive',
    FRPAdvantagesNumberFive: 'Multi-colored',
    FRPAdvantagesNumberSix: 'Ease to assemble',
    FRPAdvantagesNumberSeven: 'High safety',
    FRPUtilitiesNumberOne: 'Cooling Plant Towers',
    FRPUtilitiesNumberTwo: 'Trusses',
    FRPUtilitiesNumberThree: 'Guardrail - handrail',
    FRPUtilitiesNumberFour: ' Doors and Windows',
    FRPUtilitiesNumberFive: 'Pultruded grating',
    FRPUtilitiesNumberSix: 'Multi-Purpose ladders',

    TypeTwoDescription:'Our company is from the earliest company which entered and made this product in Egypt and we have two ways for the manufacture of grating ( mould grating - Pultruded )',
    GratingAdvantagesNumberOne: 'Light weight & high strength',
    GratingAdvantagesNumberTwo: 'Resistance to corrosion',
    GratingAdvantagesNumberThree: 'Multi Colors',
    GratingAdvantagesNumberFour: 'Non-magnetism and non-conductive',
    GratingAdvantagesNumberFive: 'Resistance to airborne and chemicals factors',
    GratingAdvantagesNumberSix: 'Do not maintenance',
    GratingUtilitiesNumberOne:'Geometric power plants',
    GratingUtilitiesNumberTwo:'Wastewater - Treatment plants',
    GratingUtilitiesNumberThree:'Covers of Sewages',
    GratingUtilitiesNumberFour:'Petroleum Companies',

    TypeThreeDescription:'Fiberglass tanks are better quality and less expensive than metal tanks, and these sectors include (spherical - cylindrical - square ) And in horizontal and vertical shapes with sizes ranging from 1/2 m3 to 1000 m3',
    TanksAdvantagesNumberOne: 'Lightweight and easy to transport',
    TanksAdvantagesNumberTwo: 'It does not absorb heat',
    TanksAdvantagesNumberThree: 'It is not affected by external weather factors',
    TanksAdvantagesNumberFour: 'Fixed colors and not affected by different weather factors',
    TanksAdvantagesNumberFive: 'It does not interact with water at all and is suitable for storing drinking water',
    TanksUtilitiesNumberOne: 'Drinking Water',
    TanksUtilitiesNumberTwo: 'Chemical Storage',
    TanksUtilitiesNumberThree: 'Fire Extinguishing',

    TypeFourDescription: 'Techno Fiber offers a distinct range of cabins and mobile caravans made of fiberglass in all its square and round shapes, with different sizes, in high quality.',
    BoothAdvantagesNumberOne: ' Lightweight and easy to transport',
    BoothAdvantagesNumberTwo: 'Ease of assembly',
    BoothAdvantagesNumberThree: 'Full equipment from the entrances and exits of electricity',
    BoothAdvantagesNumberFour: 'The speed of construction',
    BoothAdvantagesNumberFive: 'High stability ability',
    BoothAdvantagesNumberSix: 'Fixed and multiple colors',
    BoothAdvantagesNumberSeven: 'Does not need paints',
    BoothUtilitiesNumberOne:'Guard booths',
    BoothUtilitiesNumberTwo:'Sales booths',
    BoothUtilitiesNumberThree:'Offices for administration',

    TypeFiveDescription:'The company is certified by the National Authority for Drinking Water and Sewerage.',
    ManholeAdvantagesNumberOne:'Strength and durability',
    ManholeAdvantagesNumberTwo:'Not exposed to theft due to the inability to recycle',
    ManholeAdvantagesNumberThree:'Excellent friction resistance',
    ManholeAdvantagesNumberFour:'High acid and chemical resistance',
    ManholeAdvantagesNumberFive:'Not rust or corrosion',
    ManholeAdvantagesNumberSix:'Ease of installation and maintenance',
    ManholeAdvantagesNumberSeven:'Multiple shapes and colors',
    ManholeAdvantagesNumberEight:'High resistance to sunlight and weather factors',

    TypeSixDescription:'The company provides the latest methods of insulation from the uses of fiberglass to the insulation works for roofs, homes, and tanks, and also in the chemical industry, fiberglass is used to insulate chemical basins.',
    LiningAdvantagesNumberOne:'High chemical resistance ability',
    LiningAdvantagesNumberTwo:' Resistance to changing weather conditions',
    LiningAdvantagesNumberThree:'Lightweight with high durability',
    LiningAdvantagesNumberFour:'Multiple colors',
    LiningAdvantagesNumberFive:'Resistant to rust',
    LiningAdvantagesNumberSix:'Resistant to algae',
    LiningAdvantagesNumberSeven:'Merges easily with concrete surfaces',
    LiningAdvantagesNumberEight:'Not to leak water',
    LiningUtilitiesNumberOne:'Surface insulation works',
    LiningUtilitiesNumberTwo:'Home isolation works',
    LiningUtilitiesNumberThree:'Isolate tanks',
    LiningUtilitiesNumberFour:'Chemical industries',
    LiningUtilitiesNumberFive:'Swimming pools',
    LiningUtilitiesNumberSix:'Concrete and iron tanks',

    TypeSevenDescription:'Techno Fiber company offers a large, varied, and modern group of fiberglass children games and everything related to equipping nurseries, clubs, and gardens, We can provide children’s fiberglass toys in different specifications and sizes upon request',
    KidAdvantagesNumberOne:'Stamina',
    KidAdvantagesNumberTwo:'Weather resistance',
    KidAdvantagesNumberThree:'Lightness of shocks',
    KidAdvantagesNumberFour:'Multiple colors',

    TypeEightDescription:'The company offers corrugated sheets made of fiberglass reinforced with polyester, greased with smooth Gelcoat that withstands weather conditions.',
    CorrugatedAdvantagesNumberOne:'Fixed colors that do not change over time',
    CorrugatedAdvantagesNumberTwo:'It absorbs vibrations and magnetic resonance',
    CorrugatedAdvantagesNumberThree:'Against electric lightning strikes',
    CorrugatedAdvantagesNumberFour:'Does not require paints maintenance',
    CorrugatedAdvantagesNumberFive:'High stability ',
    CorrugatedAdvantagesNumberSix:'Smooth surface',
    CorrugatedAdvantagesNumberSeven:'High dynamic stamina',
    CorrugatedUtilitiesNumberOne:'Covering the ceilings',

    TypeNineDescription:'The company provides all types and sizes of chairs for clubs, stadiums, and breaks made of high-quality fiberglass.',
    ChairsAdvantagesNumberOne:'Fixed colors',
    ChairsAdvantagesNumberTwo:'Stamina ',
    ChairsAdvantagesNumberThree:'Multi-colors',
    ChairsUtilitiesNumberOne:'Clubs',
    ChairsUtilitiesNumberTwo:'Stadiums',
    ChairsUtilitiesNumberThree:' Waiting Yards',
    ChairsUtilitiesNumberFour:'Railroad Chairs',

    tanks:'Tanks',
    caravans:'Caravans',
    panels:'Panels',
    cabins:'Cabins',
    chairs:'Chairs',
    kids:'Kids Games & Toys',
    garbage:'Manhole cover projects',
    lining: 'Lining Works',
    differentSectors: 'FRP Pultrusion Profile',
    FRP:'FRP Grating',

    tanks2:'Tanks to the Central Bank of Egypt',
    tanks3:'Tanks for the Ministry of Foreign Affairs',
    tanks4:'Tanks for the Ministry of Administrative Development',
    tanks5:'Tanks for the Ministry of Social Affairs',
    tanks6:'Tanks for the Horticultural Research Institute',
    tanks8:'Tanks for Ain Shams University Hospital',
    tanks9:'Tanks for Cairo University',

    caravans1: 'Booths for the New Cairo City Authority',
    caravans2: 'Booths for the National Bank of Egypt',
    caravans3: 'Booths for the Ministry of Foreign Affairs',
    caravans4: 'Booths for EgyptAir',
    caravans5: 'Stalls of the General Traffic Department',
    caravans6: 'Booths for the New Cairo City Authority',

    panels1:'Supplying Marjeh plates to the Belayim Petroleum Company',
    panels2:'Supplying corrugated board, Mazen Engineering Office',
    panels3:'Supplying mirrored panels for Amiriya Cement Company',
    panels4:'Supplying corrugated sheets to Astelco',
    panels5:'Supplying sheets to the Egyptian Petrochemical Company',
   
    cabins1: 'Car cabins of the General Administration of Police Supply',
    cabins2: 'The cabins of the Jacques pickup company',
    cabins3: 'Engineering Industries car cabins',
    cabins4: 'Car cabins of the Greater Cairo Water Authority',
    cabins5: 'Car cabins of the Italian embassy in Cairo',
    cabins6: 'Giza Governorate car cabins',

    chairs1:'Assiut University stadium chairs',
    chairs2:'Minia University stadium chairs',
    chairs3:'Covered hall chairs at Minia University',
    chairs4:'Chairs and stands at South Valley University in Sohag',
    chairs5:'The Thunderbolt Stadium Chairs In Anshas',
    chairs6:'Umbrella stadium chairs in the presence of Armed Forces',

    kids1:'Games park at Smouha Social Sports Club',
    kids2:'Safety Land entertainment city in Sharm El Sheikh',
    kids3:'Djoser Language School',
    kids4:'Lake Casino Gate 2 Marina',
    kids5:'Osmason Club in Ismailia',
    kids6:'Childrens play works for Royal Academy Schools',

    garbage1:'Manhole cover in October',
    garbage2:'Manhole cover in Sheikh Zayed',
    garbage3:'Manhole cover in Bani Sweif',
    garbage4:'Manhole cover in El Gharbia',
    garbage5:'Manhole cover in El Sharkia',

    lining1:'Tanks and semi basins for all the stations of the Water and Sewerage Company in Giza',
    lining2:'Black Basins Dakahlia Sugar Company',
    lining3:'Cooling Towers in the Administrative Capital',
    lining4:'Dormitory for washing and cleaning trolleys at SEMAF Factory (Arab Organization for Industrialization)',
    
    
    differentSectors1:'Supply and installation of the Egyptian Petrochemical Company',
    differentSectors2:'Supply and installation of the drinking water and sanitation company in Al Gharbia',
    differentSectors3:'Supply and installation of Talaat Mostafa Group',
    differentSectors4:'Supply and installation of Dakahlia Sugar Company',
    differentSectors5:'Supplying and installing Petrojet company, the eastern branch',

    FRP1:'Supplying and installing a walker for the petrochemical company',
    FRP2:'Supplying and installing chat for Sidi Kerir company',
    FRP3:'Supply and installation of walkers for Talaat Moustafa companies',
    FRP4:'The drinking water and wastewater company in Giza',
    FRP5:'Supply and installation of all fiberglass components needed for sewage stations in Al-Qalaj city',
    
    TypeTenDescription:'The company provides all types and sizes of chairs for clubs, stadiums, and breaks made of high-quality fiberglass.',
    
});