import './main.scss';
import './locales/ar';
import './locales/en';
import styleSettings from "reactor/layout/utils/style-settings";
import localization from 'reactor/localization';

localization.extend('en', {
    appName: 'Techno-Fiber',
});

localization.extend('ar', {
    appName: 'تكنو-فايبر',
});


export const MAIN_COLOR = '#6057AB';

styleSettings.set({
    colors: {
        primary: MAIN_COLOR,
        link: MAIN_COLOR,
    },
    fontFamily: {
        en: {
            fontFamily: 'Raleway',
            src: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;1,600;1,800&display=swap',
        }
    }
});