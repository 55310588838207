import config from 'reactor/config';

const inDevelopmentMode = process.env.NODE_ENV === 'development';

config.set({
    // Services Configurations
    // A.K.A Endpoints
    basePath: '/', // for production
    // basePath: inDevelopmentMode ? '/' : '/Techno-Fiber/', // for production
    endpoint: {
        baseUrl: 'https://apps.crafted-internet.com/Techno-Fiber',
        apiKey: 'PAQWEQCZXDGSDKPW912EDWCSXP213EDAYFEKRIANOFEKRYNO1',
    },
    locales: {
        en: {
            direction: 'ltr',
        },
        ar: {
            direction: 'rtl',
        },
    },
    form: {
        input: {
            variant: 'standard'
        }
    }
});