import localization from 'reactor/localization';

localization.extend('ar', {
    Home: 'الرئيسية',
    Products: 'المنتجات',
    Projects: 'المشاريع',
    Partners: 'شركاء النجاح',
    Clients: 'عــمــلائـــنـــا',
    About:'حول الموقع',
    Contact: 'اتصل بنا',
    footerPages: 'الصفحات',
    ourLocations: 'مواقعنا',
    headOffice: 'المكتب الرئيسي والمصانع',
    octoberBranch:'فرع اكتوبر',
    officeLocation:' شارع عبدالسلام أبو العز من شارع التلاتيني، خلف ارض الجوهري، قليوب، القاهرة.',
    octoberBranchLocation:'المنطقة الصناعية، القطعة 403، اكتوبر، القاهرة.',
    technoFiber:'تكنو فايبر',
    fiberGlass:'فايبر جــــلاس',
    followUs:'تابعنا علي السوشيال ميديا',
    contactName:'ادخل الاسم بالكامل هنا..',
    contactMail:'ادخل الايميل هنا..',
    contactNumber:'ادخل رقم الهاتف بالكامل هنا..',
    contactMessage:'ادخل رسالتك هنا..',
    contactSubject:'ادخل الموضوع هنا..',
    contactBtn:'إرسال',
    viewAll:'إظهار الكل',
    
    Overview:'نظرة عامة',
    technoFiberCompany:'شركة تكنو فايبر',
    technoDescription1:'شركة رائدة متخصصة في تصنيع وتسويق منتجات الفيبرجلاس.',
    technoDescription2:'تتميز منتجات الشركة بتنوعها وجودتها العالية ، ولعبت الشركة دورًا رائدًا في إدخال صناعة الجريتينج في مصر.',
    ReadMore:'اقرأ المزيد',
    ForMore:'اقرأ المزيد',
    TechnoFiberCompany:'شركة تكنو فايبر',
    OurFactory:'مصانعنا',
    TechnoFiberCompanyDescription:'شركة رائدة متخصصة في تصنيع وتسويق منتجات الفيبر جلاس. تتميز منتجات الشركة بتنوعها وجودتها العالية. لعبت الشركة دورًا رائدًا في إدخال صناعة الجريتينج في مصر. إنها الشركة الوحيدة في مصر التي تمتلك خط Pultrusion لإنتاج بروفيلات FRP المختلفة التي تعمل كبديل لقطاعات الحديد والألمنيوم.',
    OurFactoryDescription:'شركة رائدة متخصصة في تصنيع وتسويق منتجات الفيبر جلاس. تتميز منتجات الشركة بتنوعها وجودتها العالية. لعبت الشركة دورًا رائدًا في إدخال صناعة الجريتينج في مصر. إنها الشركة الوحيدة في مصر التي تمتلك خط Pultrusion لإنتاج بروفيلات FRP المختلفة التي تعمل كبديل لقطاعات الحديد والألمنيوم.',
    OurVision:'الرؤية',
    OurVisionDescription:'لدینا خبره اكثر من ٢٥ عاما فى مجال تصمیم وتصنیع جمیع منتجات الفیبر جلاس، وتمكنا خلال ھذه الفترة من التعامل مع جمیع قطاعات الدولة، ولذلك تتطلع شركتنا الى الارتقاء بمستوى الجودة الى اعلى مقاییس الجودة العالمیة لتنافس فى جمیع الاسواق العالمیة.',
    GetInTouchWithUs:'ابق على تواصل معنا',
    Mail:'الإيميل',
    Address:'العنوان',
    AddressLocation:' شارع عبدالسلام أبو العز من شارع التلاتيني، خلف ارض الجوهري، قليوب، القاهرة.',
    SendUsMessage:'أرسل لنا رسالة',
    Phone:'الموبايل',
    FastLinks:'الصفحات',
    suggestedProducts:'منتجات مقترحة',
    advantages:'المميزات',
    utilities : 'استخدامات',

    typeOne:'قطاعات الفيبرجلاس',
    typeTwo:'المشايات',
    typeThree:'خزانات',
    typeFour:'أكشاك حراسة وكرفانات',
    typeFive:'أغطية الصرف الصحي',
    typeSix: 'أعمال التبطين والأرضيات',
    typeSeven: 'لعب الأطفال',
    typeEight: 'الألواح الفايبرجلاس',
    typeNine: 'كراسي الاستادات والصالات المغطاة',
    typeTen: 'كبائن السيارات',

    TypeOneDescription: 'تعد هذه القطاعات جديدة علي السوق المصري و العربي. فهي تستخدم بديلة عن قطاعات الحديد و الالمونيوم وتشمل هذه القطاعات ( الدائرية – المربعه – الزوايا و المجري ... )  ولهذه القطاعات قدرة كبيرة على الانتشار الواسع و السريع في عديد من المجالات لما تتميز به من تعدد الاستخدامات',
    FRPAdvantagesNumberOne: 'مقاومة عالية للعوامل الجوية و الكيميائية',
    FRPAdvantagesNumberTwo: ' خفة الوزن مع الصلابة العالية',
    FRPAdvantagesNumberThree: ' لا تصدأ',
    FRPAdvantagesNumberFour: 'عزل تام للكهرباء',
    FRPAdvantagesNumberFive: 'ألوان متعددة',
    FRPAdvantagesNumberSix: 'سهولة التجميع',
    FRPAdvantagesNumberSeven: 'الأمان العالي',
    FRPUtilitiesNumberOne: ' أبراج محطات التبريد',
    FRPUtilitiesNumberTwo: ' الجمالونات',
    FRPUtilitiesNumberThree: 'الأسوار',
    FRPUtilitiesNumberFour: 'الأبواب و الشبابيك',
    FRPUtilitiesNumberFive: 'المشايات',
    FRPUtilitiesNumberSix: ' السلالم المختلفة الأغراض',

    TypeTwoDescription:'تعد شركتنا من أول من أدخل صنع هذا المنتج بمصر ',
    GratingAdvantagesNumberOne: ' خفة الوزن مع الصلابة العالية',
    GratingAdvantagesNumberTwo: ' لا تصدأ',
    GratingAdvantagesNumberThree: 'عزل تام للكهرباء',
    GratingAdvantagesNumberFour: ' ألوان متعددة',
    GratingAdvantagesNumberFive: ' مقاومة للعوامل الجوية و الكيميائية',
    GratingAdvantagesNumberSix: 'لا تحتاج الى صيانة ',
    GratingUtilitiesNumberOne:'محطة القوي الهندسية',
    GratingUtilitiesNumberTwo:'محطات معالجة مياه الصرف الصحي',
    GratingUtilitiesNumberThree:'أغطية الصرف',
    GratingUtilitiesNumberFour:' شركات البترول',

    TypeThreeDescription:'تعد خزانات فيبرجلاس أفضل جودة وأقل تكلفة من الخزانات المعدنية, وتشمل هذه القطاعات ( كروية - اسطوانية - مربعة) وبأشكال أفقية ورأسية بأحجام تبدأ من ½ م3 حتى 1000م',
    TanksAdvantagesNumberOne: 'خفة الوزن و يسهل نقله',
    TanksAdvantagesNumberTwo: 'لا يمتص الحرارة',
    TanksAdvantagesNumberThree: 'لا يتأثر بالعوامل الجوية الخارجية',
    TanksAdvantagesNumberFour: ' ألوان ثابتة ولا تتأثر بالعوامل الجوية المختلفة',
    TanksAdvantagesNumberFive: 'لا يتفاعل مع المياه مطلقًا و صالح لتخزين مياه الشرب',
    TanksUtilitiesNumberOne: 'مياه الشرب',
    TanksUtilitiesNumberTwo: 'تخزين مواد كيميائية',
    TanksUtilitiesNumberThree: 'إطفاء',

    TypeFourDescription: 'تقدم تكنو فايبر تشكيله متميزة من الكبائن والكرفانات المتنقلة من الفيبر جلاس بجميع اشكالها المربع والدائرية بمقاسات مختلفة و بمواصفات وجودة عالية.',
    BoothAdvantagesNumberOne: 'خفة الوزن و يسهل نقله',
    BoothAdvantagesNumberTwo: 'سهولة التجميع',
    BoothAdvantagesNumberThree: 'كامل التجهيزات من مداخل ومخارج الكهرباء والتليفون',
    BoothAdvantagesNumberFour: 'سرعة البناء',
    BoothAdvantagesNumberFive: 'قدرة ثبات عالية',
    BoothAdvantagesNumberSix: 'ألوان ثابتة ومتعددة',
    BoothAdvantagesNumberSeven: ' لاتحتاج إلي دهانات',
    BoothUtilitiesNumberOne:'أكشاك الحراسة',
    BoothUtilitiesNumberTwo:' نوافذ للبيع',
    BoothUtilitiesNumberThree:'مكاتب للإدارة',

    TypeFiveDescription:'الشركة معتمدة من الهيئة القومية لمياه الشرب والصرف الصحي',
    ManholeAdvantagesNumberOne:'القوة والمتانة',
    ManholeAdvantagesNumberTwo:'غير معرضة للسرقة بسبب عدم امكانية إعادة التصنيع',
    ManholeAdvantagesNumberThree:'مقاومة ممتازة للاحتكاك',
    ManholeAdvantagesNumberFour:'مقاومة عالية للأحماض والكيماويات',
    ManholeAdvantagesNumberFive:'غير قابلة للصدأ أو التآكل',
    ManholeAdvantagesNumberSix:'سهولة التركيب والصيانة',
    ManholeAdvantagesNumberSeven:'متعددة الاشكال والالوان',
    ManholeAdvantagesNumberEight:'مقاومة عالية لأشعة الشمس والعوامل الجوية',

    TypeSixDescription:'توفر الشركة أحدث أساليب العزل من استخدامات الفيبر جلاس اعمال العزل الخاصة بالأسطح والمنازل وعزل الخزانات, وأيضا في الصناعات الكيميائية يستخدم الفيبر جلاس في عزل أحواض الجلفنة والأحواض الكيميائية.',
    LiningAdvantagesNumberOne:'القدرة العالية على المقاومة الكيميائية',
    LiningAdvantagesNumberTwo:'مقاومة الظروف الجوية المتغيرة',
    LiningAdvantagesNumberThree:'خفيفة الوزن مع المتانة العالية',
    LiningAdvantagesNumberFour:'متعددة الألوان',
    LiningAdvantagesNumberFive:' مقاومة للصدأ',
    LiningAdvantagesNumberSix:'مقاومة للطحالب',
    LiningAdvantagesNumberSeven:'تندمج بسهولة مع الأسطح الخرسانية',
    LiningAdvantagesNumberEight:' عدم تسرب المياه',
    LiningUtilitiesNumberOne:'اعمال العزل الخاصة بالأسطح',
    LiningUtilitiesNumberTwo:' أعمال عزل المنازل',
    LiningUtilitiesNumberThree:'عزل الخزانات',
    LiningUtilitiesNumberFour:'الصناعات الكيميائية',
    LiningUtilitiesNumberFive:'حمامات السباحة',
    LiningUtilitiesNumberSix:' الخزانات الخرسانية والحديدية',

    TypeSevenDescription:'شركة تكنو فايبر تقدم مجموعه كبيره ومتنوعه وعصرية من العاب اطفال فيبر جلاس وكل ما يخص تجهيز الحضانات والنوادى والحدائق,  يمكننا توفير العاب اطفال فيبر جلاس بمواصفات مختلفة ومقاسات مختلفة حسب الطلب.',
    KidAdvantagesNumberOne:'قوة التحمل',
    KidAdvantagesNumberTwo:'مقاومة العوامل الجوية',
    KidAdvantagesNumberThree:'خفة الصدمات',
    KidAdvantagesNumberFour:'متعددة الألوان',

    TypeEightDescription:' تقدم الشركة الواح معرج مصنوعة من مادة فيبر جلاس المسلح بمادة البولي استر المدهون بمادة الجيلكوت الملساء التي تتحمل العوامل الجوية.',
    CorrugatedAdvantagesNumberOne:'ألوان ثابتة لا تتغير مع مرور الوقت',
    CorrugatedAdvantagesNumberTwo:'يمتص الاهتزازات والرنين المغنطيسي',
    CorrugatedAdvantagesNumberThree:' ضد الصواعق الكهربائية',
    CorrugatedAdvantagesNumberFour:'لا يحتاج لصيانة دهانات',
    CorrugatedAdvantagesNumberFive:' قدرة ثبات عالية ',
    CorrugatedAdvantagesNumberSix:'سطح أملس',
    CorrugatedAdvantagesNumberSeven:' قوة احتمال ديناميكية عالية',
    CorrugatedUtilitiesNumberOne:'تغطية الأسقف',

    TypeNineDescription:'توفر الشركة جميع انواع ومقاسات كراسي النوادى والاستادات والاستراحات المصنعة من الفيبر جلاس عالي الجودة.',
    ChairsAdvantagesNumberOne:'ألوان ثابتة',
    ChairsAdvantagesNumberTwo:'قوة التحمل ',
    ChairsAdvantagesNumberThree:'متعددة الألوان',
    ChairsUtilitiesNumberOne:'النوادي',
    ChairsUtilitiesNumberTwo:'الاستادات',
    ChairsUtilitiesNumberThree:' ساحات الانتظار',
    ChairsUtilitiesNumberFour:'كراسي سكك الحديد',

    tanks:'الخزانات',
    caravans:'الأكشاك',
    panels:'الألواح',
    cabins:'الكبائن',
    chairs:'الكراسي',
    kids:'لعب الأطفال',
    garbage:'صناديق جمع القمامة',
    lining: 'أعمال التبطين',
    differentSectors: 'قطاعات مختلفة',
    FRP:'المشايات',

    tanks2:'خزانات للبنك المركزي المصري',
    tanks3:'خزانات لوزارة الخارجية',
    tanks4:'خزانات لوزارة التنمية الادارية',
    tanks5:'خزانات لوزارة الشؤون الاجتماعية',
    tanks6:'خزانات لمعهد بحوث البساتين',
    tanks8:'خزانات لمستشفى جامعة عين شمس',
    tanks9:'خزانات لجامعة القاهرة',

    caravans1: 'اكشاك لجهاز مدينة القاهرة الجديدة',
    caravans2: ' اكشاك للبنك الاهلي المصري',
    caravans3: 'اكشاك لوزارة الخارجية',
    caravans4: 'أكشاك لمصر للطيران',
    caravans5: 'أكشاك الإدارة العامة للمرور',
    caravans6: 'أكشاك لهيئة مدينة القاهرة الجديدة',
 
    panels1:'توريد الواح معرجة لشركة بترول بلاعيم ',
    panels2:'توريد الواح معرجة مكتب مازن الهندسي',
    panels3:'توريد الواح معرجة لشركة اسمنت العامرية ',
    panels4:'توريد الواح معرجة لشركة استيلكو',
    panels5:'توريد الواح لشركة البتروكيماويات المصرية ',

    cabins1:'كبائن سيارات الادارة العامة لامداد الشرطة',
    cabine2:'كبائن سيارات شركة جاك بيك اب',
    cabins3:'كبائن سيارات الشركة الهندسية للصناعات',
    cabins4:'كبائن سيارات هيئة مياه القاهرة الكبرى',
    cabins5:'كبائن سيارات السفارة الايطالية بالقاهره',
    cabins6:'كبائن سيارات محافظة الجيزة',

    chairs1:'كراسي ستاد جامعة اسيوط',
    chairs2:'كراسي ستاد جامعة المنيا',
    chairs3:'كراسي الصالة المغطاة بجامعة المنيا',
    chairs4:'كراسي الصالة المغطاة بجامعة المنيا بمدينة المنيا ',
    chairs5:'كراسي ستاد الصاعقة بإنشاص',
    chairs6:'كراسي ستاد المظلات بإنشاص',

    kids1:'حديقة ألعاب بنادي سموحة الرياضي الاجتماعي',
    kids2:'مدينة سيفتي لاند الترفيهية بمدينة شرم الشيخ',
    kids3:'مدرسة زوسر الخاصة للغات',
    kids4:'كازينو البحيرة البوابه ٢ مارينا',
    kids5:'نادي عثماسون بمدينة الاسماعيلية',
    kids6:'اعمال لعب الاطفال لمدارس رويال اكاديمي',

    garbage1:'أغطية الصرف الصحي والصفايات في اكتوبر',
    garbage2:'أغطية الصرف الصحي والصفايات في الشيخ زايد',
    garbage3:'أغطية الصرف الصحي والصفايات في بني سويف',
    garbage4:'أغطية الصرف الصحي والصفايات في الشرقية',
    garbage5:'أغطية الصرف الصحي والصفايات في الغربية',

    lining1:'خزانات واحواض الشبه لجميع محطات ‏شركة مياه الشرب والصرف الصحي بالجيزة',
    lining2:'أحواض السوداء بشركة الدقهلية للسكر',
    lining3:'أبراج تبريد ‏بالعاصمة الإدارية',
    lining4:'‏عنبر غسيل وتنظيف العربات بمصنع سيماف ( الهيئة العربية للتصنيع )',

    differentSectors1:'توريد وتركيب بشركة البتروكيماويات المصرية',
    differentSectors2:'توريد وتركيب بشركة مياه الشرب والصرف الصحي بالغربية',
    differentSectors3:'توريد وتركيب مجموعة شركة طلعت مصطفى',
    differentSectors4:'توريد وتركيب شركة الدقهلية للسكر',
    differentSectors5:'توريد وتركيب شركة بتروجت الفرع الشرقي',

    FRP1:'توريد وتركيب مشاية لشركة البتروكيماويات',
    FRP2:'توريد وتركيب الشات لشركة سيدي كرير',
    FRP3:'توريد وتركيب مشايات لشركات طلعت مصطفى',
    FRP4:'شركة مياه الشرب والصرف الصحي بالجيزة',
    FRP5:'توريد وتركيب جميع المكونات الفايبرجلاس لزوم محطات الصرف الصحي بمدينة القلج ',
    
});