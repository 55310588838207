import { setModules } from "reactor/router/modules-list";

setModules([
    // application
    {
        path: '/',
        name: 'front-office',
        loadMain: true,
        modules: [
            {
                entry: ['/', '/about-us','/contact-us','/our-projects','/our-products',],
                module: 'home',
            },
        ]
    }
]);